import React from "react"
import withI18next from '../components/withI18next';
import Layout from "../components/layout"
import SEO from "../components/seo"


const IntroductionPage = ({ pageContext: { locale }, t}) => {
  return <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    {locale}
  </Layout>
}

export default withI18next({ns: 'common'})(IntroductionPage);
